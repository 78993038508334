import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { trackError } from '../lib/analytics';

export default function ResetPassword() {
  const { supabase } = useAuth();
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { } = useLocation();

  useEffect(() => {
    const handleRecoveryToken = async () => {
      const fragment = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = fragment.get('access_token');
      const refreshToken = fragment.get('refresh_token');
      const type = fragment.get('type');

      if (!accessToken || type !== 'recovery') {
        navigate('/sign-in');
        return;
      }

      try {
        const { data, error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken
        });

        if (error) throw error;
        if (!data?.session) {
          navigate('/sign-in');
        }
      } catch (err) {
        console.error('Error setting session:', err);
        navigate('/sign-in');
      }
    };

    handleRecoveryToken();
  }, [navigate, supabase.auth]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!newPassword) {
      setError('Please enter a new password');
      return;
    }

    setError('');
    setSuccess('');
    setLoading(true);

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      setSuccess('Password updated successfully! Redirecting...');
      setTimeout(() => {
        navigate('/sign-in');
      }, 2000);
    } catch (err) {
      console.error('Error:', err);
      trackError('reset-password', err.message);
      setError(err.message || 'Failed to update password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
      <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4">
        <h2 className="text-3xl font-bold mb-6">Reset Password</h2>

        <form onSubmit={handlePasswordReset} className="space-y-4">
          <div>
            <label htmlFor="newPassword" className="sr-only">New Password</label>
            <input
              id="newPassword"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
              required
              minLength={6}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
            />
          </div>

          {error && (
            <p className="text-sm text-red-500">{error}</p>
          )}
          {success && (
            <p className="text-sm text-green-500">{success}</p>
          )}

          <button
            type="submit"
            disabled={loading}
            className="w-full p-2 bg-black text-white rounded hover:bg-gray-800 disabled:opacity-50"
          >
            {loading ? "Updating Password..." : "Update Password"}
          </button>
        </form>
      </div>
    </div>
  );
} 