// src/components/UserProfile.js

import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import QuestionActions from './QuestionActions';  // Add this import

export default function UserProfile() {
  const { supabase, user } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState(user?.user_metadata?.full_name || '');
  const [isEditingName, setIsEditingName] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [expandedEnergizers, setExpandedEnergizers] = useState(new Set());

  const handleSignOut = async () => {
    try {
      setError('');
      const { error } = await supabase.auth.signOut();
      
      if (error) {
        console.error('Sign out error:', error);
        throw error;
      }

      // Clear any local state
      localStorage.clear();
      sessionStorage.clear();
      
      // Navigate to home page
      window.location.href = '/';
      
    } catch (err) {
      console.error('Error signing out:', err);
      // Still redirect even if there's an error
      window.location.href = '/';
    }
  };

  const fetchUserStats = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('generated_questions')
        .select('*')
        .eq('user_id', user.id);

      if (error) throw error;

      console.log('User Stats:', data);
    } catch (err) {
      console.error("Error fetching user stats:", err);
      setError("Failed to fetch user statistics.");
    }
  }, [supabase, user]);

  const fetchSavedQuestions = useCallback(async () => {
    try {
      const { data: savedData, error: savedError } = await supabase
        .from('saved_questions')
        .select(`
          *,
          generated_questions (
            id,
            question,
            type,
            mood,
            complexity,
            votes_questions (
              vote_type,
              user_id
            )
          )
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (savedError) throw savedError;

      if (savedData) {
        const formattedQuestions = savedData
          .filter(sq => sq.generated_questions) // Filter out any null references
          .map(sq => ({
            id: sq.generated_questions.id,
            content: sq.generated_questions.question,
            type: sq.generated_questions.type,
            mood: sq.generated_questions.mood,
            complexity: sq.generated_questions.complexity,
            votes_questions: sq.generated_questions.votes_questions
          }));

        setSavedQuestions(formattedQuestions);
      }
    } catch (err) {
      console.error('Error fetching saved questions:', err);
      setError('Failed to fetch saved questions.');
    }
  }, [supabase, user]);

  useEffect(() => {
    if (user) {
      setName(user.user_metadata.full_name || '');
      fetchUserStats();
      fetchSavedQuestions();
    }
  }, [user, fetchUserStats, fetchSavedQuestions]);

  const handleNameUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      // First update the user metadata
      const { error: userError } = await supabase.auth.updateUser({
        data: { full_name: name }
      });

      if (userError) throw userError;

      // Then update the profile using 'id' column
      const { error: profileError } = await supabase
        .from('profiles')
        .update({ full_name: name })
        .eq('id', user.id);  // Using 'id' as the column name

      if (profileError) throw profileError;

      setSuccess('Name updated successfully');
      setIsEditingName(false);
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      console.error('Error updating name:', err);
      setError('Failed to update name: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;
      
      setNewPassword('');
      setShowPasswordInput(false); // Hide input after successful change
      setSuccess('Password updated successfully');
      setTimeout(() => setSuccess(''), 3000); // Clear success message after 3 seconds
    } catch (error) {
      setError('Failed to update password: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return; // Wait for the user to confirm
    }
  
    setLoading(true);
    setError('');
  
    try {
      // Delete related data first
      const { error: questionsError } = await supabase
        .from('generated_questions')
        .delete()
        .eq('user_id', user.id);
      if (questionsError) throw questionsError;

      const { error: votesError } = await supabase
        .from('votes_questions')
        .delete()
        .eq('user_id', user.id);
      if (votesError) throw votesError;

      const { error: savedError } = await supabase
        .from('saved_questions')
        .delete()
        .eq('user_id', user.id);
      if (savedError) throw savedError;

      const { error: profileError } = await supabase
        .from('profiles')
        .delete()
        .eq('id', user.id);
      if (profileError) throw profileError;

      // Finally, delete the user's auth account
      const { error: deleteError } = await supabase.rpc('delete_user');
      if (deleteError) throw deleteError;

      // If successful, sign out and redirect
      await supabase.auth.signOut();
      navigate('/', { replace: true });
    } catch (err) {
      console.error('Error deleting account:', err);
      setError(`Failed to delete account: ${err.message}`);
    } finally {
      setLoading(false);
      setConfirmDelete(false);
    }
  };

  const toggleEnergizer = (id) => {
    setExpandedEnergizers(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  return (
    <>
      <Helmet>
        <title>Profile - Check-In Questions</title>
        <meta name="description" content="Manage your Check-In Questions profile, view saved questions and energizers, and customize your account settings." />
        <meta property="og:title" content="Profile - Check-In Questions" />
        <meta property="og:description" content="Manage your Check-In Questions profile, view saved questions and energizers, and customize your account settings." />
      </Helmet>
      <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
          <div className="flex flex-col mb-8">
            <h2 className="text-3xl font-bold">User Profile</h2>
            <div className="text-xl font-semibold">
              <p className="text-3xl mt-6">{name || 'No name set'}</p>
              <p className="text-gray-400">{user.email}</p>
            </div>
          </div>

          {/* Status Messages */}
          <div className="h-4 mt-2">
            {error && (
              <p className="text-sm text-red-500 text-center">{error}</p>
            )}
            {success && (
              <p className="text-sm text-green-500 text-center">{success}</p>
            )}
          </div>

          {/* Account Actions */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Account Actions</h3>
            
            <div className="space-y-3">
              {isEditingName ? (
                <form onSubmit={handleNameUpdate} className="space-y-3">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter your name"
                    required
                  />
                  <div className="flex gap-2">
                    <button
                      type="submit"
                      disabled={loading}
                      className="flex-1 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50"
                    >
                      {loading ? 'Saving...' : 'Save'}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsEditingName(false);
                        setName(user?.user_metadata?.full_name || '');
                      }}
                      className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              ) : (
                <button
                  onClick={() => setIsEditingName(true)}
                  className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Change Name
                </button>
              )}

              {showPasswordInput ? (
                <form onSubmit={handlePasswordChange} className="space-y-3">
                  <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                    minLength={6}
                  />
                  <div className="flex gap-2">
                    <button
                      type="submit"
                      className="flex-1 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                    >
                      Update Password
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setShowPasswordInput(false);
                        setNewPassword('');
                      }}
                      className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              ) : (
                <button
                  onClick={() => setShowPasswordInput(true)}
                  className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Change Password
                </button>
              )}

              <button
                onClick={handleSignOut}
                className="w-full px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors"
              >
                Sign Out
              </button>

              {confirmDelete ? (
                <div className="space-y-2">
                  <p className="text-sm text-gray-600">Are you sure? This cannot be undone.</p>
                  <div className="flex gap-2">
                    <button
                      onClick={handleDeleteAccount}
                      className="flex-1 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                    >
                      Yes, Delete Account
                    </button>
                    <button
                      onClick={() => setConfirmDelete(false)}
                      className="flex-1 px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => setConfirmDelete(true)}
                  className="w-full px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                >
                  Delete Account
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Saved Questions Section */}
        {savedQuestions.length > 0 && (
              <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
            <h2 className="text-2xl font-bold mb-4">Saved Questions</h2>
            <div className="space-y-4">
              {savedQuestions.map((question) => (
                <div key={question.id} className="rounded-lg shadow-lg p-4">
                  <div className="space-y-4">
                    {question.type === 'energizer' ? (
                      <div>
                        {(() => {
                          try {
                            const parsedContent = JSON.parse(question.content);
                            const isExpanded = expandedEnergizers.has(question.id);
                            
                            return (
                              <div className="text-left">
                                {/* Preview Section - Always Visible */}
                                <div className="space-y-4">
                                  <h2 className="text-4xl font-bold mb-4">{parsedContent.emoji} {parsedContent.title}</h2>
                                  <p className="text-xl mb-8">{parsedContent.description}</p>
                                </div>

                                {/* Expandable Section */}
                                <div className="relative mt-4">
                                  <button
                                    onClick={() => toggleEnergizer(question.id)}
                                    className="flex items-center gap-2 text-blue-500 hover:text-blue-600 transition-colors mb-4"
                                  >
                                    <span className="font-medium">
                                      {isExpanded ? 'Show less' : 'Read more'}
                                    </span>
                                    {isExpanded ? <CaretUp size={20} /> : <CaretDown size={20} />}
                                  </button>

                                  {isExpanded && (
                                    <div className="space-y-6 mt-4">
                                      <div className="mb-8 bg-gray-100 p-6 rounded-lg">
                                        <p className="text-xl mb-2">⏱️ <strong>Time:</strong> {parsedContent.timeFrame}</p>
                                        <p className="text-xl mb-2">👥 <strong>Group Size:</strong> {parsedContent.groupSize}</p>
                                        <p className="text-xl mb-2">🎯 <strong>Purpose:</strong> {parsedContent.purpose}</p>
                                      </div>

                                      <div className="mb-8">
                                        <h3 className="text-2xl font-bold mb-4">Steps</h3>
                                        <ol className="list-decimal list-outside pl-5 space-y-3 text-xl">
                                          {parsedContent.steps.map((step, index) => (
                                            <li key={index}>{step.replace(/^Step \d+:\s*/, '')}</li>
                                          ))}
                                        </ol>
                                      </div>

                                      <div>
                                        <h3 className="text-2xl font-bold mb-4">Facilitator Notes</h3>
                                        <ul className="list-disc list-outside pl-5 space-y-3 text-xl">
                                          {parsedContent.facilitatorNotes.map((note, index) => (
                                            <li key={index}>{note.replace(/^Note \d+:\s*/, '')}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          } catch (e) {
                            console.error('Error parsing energizer content:', e);
                            return <p className="text-red-500">Error displaying energizer content</p>;
                          }
                        })()}
                      </div>
                    ) : (
                      <h2 className="text-4xl font-bold mb-4">&ldquo;{question.content}&ldquo;</h2>
                    )}
                    <div className="flex items-center justify-between">

                      <QuestionActions
                        question={question}
                        onVote={() => fetchSavedQuestions()}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
