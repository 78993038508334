import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';

const SubscriptionContext = createContext();

export function useSubscriptionStatus() {
  return useContext(SubscriptionContext);
}

export function SubscriptionProvider({ children }) {
  const { user, supabase } = useAuth();
  const [subscription, setSubscription] = useState({
    status: 'free',
    isActive: false
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    async function fetchSubscription() {
      if (!user) {
        if (isMounted) {
          setSubscription({
            status: 'free',
            isActive: false
          });
          setLoading(false);
        }
        return;
      }

      try {
        // Fetch subscription status from profiles table
        const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('subscription_status')
          .eq('id', user.id)
          .single();

        if (profileError) {
          console.error('Profile fetch error:', profileError);
          if (isMounted) {
            setSubscription({
              status: 'free',
              isActive: false
            });
          }
        } else {
          // Valid profile data found
          if (isMounted) {
            const status = profileData?.subscription_status || 'free';
            setSubscription({
              status: status,
              isActive: status === 'premium'
            });
          }
        }
      } catch (error) {
        console.error('Subscription check failed:', error);
        if (isMounted) {
          setSubscription({
            status: 'free',
            isActive: false
          });
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchSubscription();
    
    // Subscribe to profile changes
    const channel = supabase
      .channel('profile-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'profiles',
          filter: `id=eq.${user?.id}`
        },
        () => {
          fetchSubscription();
        }
      )
      .subscribe();

    return () => {
      isMounted = false;
      supabase.removeChannel(channel);
    };
  }, [user, supabase]);

  const value = {
    subscription,
    loading,
    isSubscribed: subscription.status === 'premium',
    isPremium: subscription.status === 'premium',
    isFree: subscription.status !== 'premium'
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
} 