// src/components/checkingenerator.js

import React, { useRef, memo, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSubscriptionStatus } from '../contexts/SubscriptionContext';
import QuestionActions from './QuestionActions';
import { useQuestionGenerator } from '../hooks/useQuestionGenerator';
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { Helmet } from 'react-helmet-async';

// Memoized QuestionActions component
const MemoizedQuestionActions = memo(QuestionActions);

// Memoized control option
const ControlOption = memo(({ 
  label, 
  emoji, 
  isSelected, 
  onChange 
}) => (
  <label className="flex items-center justify-between text-xl">
    <span>{emoji} {label}</span>
    <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={onChange}
        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
      />
      <div
        className={`w-full h-full rounded-full ${
          isSelected ? 'bg-green-500' : 'bg-gray-200'
        }`}
      />
    </div>
  </label>
));

export default function CheckInGenerator() {
  const { user } = useAuth();
  const { subscription, isPremium } = useSubscriptionStatus();
  const questionBoxRef = useRef(null);
  const [expandedEnergizers, setExpandedEnergizers] = useState(new Set());
  const [error] = useState(null);

  const {
    generationsLeft,
    selectedType,
    selectedMood,
    selectedComplexity,
    isLoading,
    generatedQuestion,
    previousQuestions,
    handleTypeChange,
    handleMoodChange,
    handleComplexityChange,
    generateQuestion,
    fetchPreviousQuestions
  } = useQuestionGenerator();

  // Debug subscription status
  useEffect(() => {
    console.log('Current subscription status:', subscription);
  }, [subscription]);

  // Add debug logging
  useEffect(() => {
    console.log('Debug Subscription Values:', {
      user: !!user,
      isPremium,
      subscription,
      subscriptionStatus: subscription?.status,
      isActive: subscription?.isActive,
    });
  }, [user, isPremium, subscription]);

  // Scroll to top nav after generating
  const handleGenerate = async () => {
    await generateQuestion();
    // Find the header element and scroll to it
    const headerElement = document.querySelector('header');
    if (headerElement) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const toggleEnergizer = (id) => {
    setExpandedEnergizers(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  useEffect(() => {
    if (user) {
      fetchPreviousQuestions();
    }
  }, [user, selectedType, fetchPreviousQuestions]);

  return (
    <>
      <Helmet>
        <title>Check-In Questions - AI-Powered Check-In Questions Generator</title>
        <meta name="description" content="Generate engaging check-in questions, energizers, and icebreakers for your team meetings. Use AI to create personalized questions that foster connection and engagement." />
      </Helmet>
      <div ref={questionBoxRef} className="max-w-4xl mx-auto px-1 py-1 md:py-4">
        {/* Question Display Box */}
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
          {error ? (
            <div className="text-red-500 text-center">
              <p className="text-xl font-semibold mb-2">Oops! Something went wrong</p>
              <p>{error}</p>
            </div>
          ) : isLoading ? (
            <div className="text-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto mb-4"></div>
              <p className="text-xl text-gray-600">Generating your question...</p>
            </div>
          ) : !generatedQuestion ? (
            <h2 className="text-4xl text-gray-400 font-bold text-center">
              Click 'Generate' to get your{' '}
              {selectedComplexity === 'simple'
                ? 'simple'
                : selectedComplexity === 'moderate'
                ? 'moderately complex'
                : 'deep'},{' '}
              {selectedMood === 'curious'
                ? 'curious'
                : selectedMood === 'personal'
                ? 'personal'
                : 'quirky'}{' '}
              {selectedType === 'check-in'
                ? 'check-in question'
                : selectedType === 'energizer'
                ? 'team energizer'
                : 'check-out question'}
            </h2>
          ) : (
            <>
              {selectedType === 'energizer' ? (
                <div className="space-y-4">
                  {(() => {
                    try {
                      console.log('Displaying energizer:', generatedQuestion);
                      
                      if (!generatedQuestion || !generatedQuestion.title) {
                        throw new Error('Invalid energizer format');
                      }

                      return (
                        <div className="space-y-6">
                          <div className="space-y-4">
                            <h2 className="text-4xl font-bold">
                              {generatedQuestion.emoji} {generatedQuestion.title}
                            </h2>
                            <p className="text-xl">{generatedQuestion.description}</p>
                          </div>
                          <div className="mb-8 bg-gray-100 p-6 rounded-lg">
                            <p className="text-xl mb-2">⏱️ <strong>Time:</strong> {generatedQuestion.timeFrame}</p>
                            <p className="text-xl mb-2">👥 <strong>Group Size:</strong> {generatedQuestion.groupSize}</p>
                            {generatedQuestion.materials && generatedQuestion.materials !== 'None required' && (
                              <p className="text-xl mb-2">📦 <strong>Materials:</strong> {generatedQuestion.materials}</p>
                            )}
                            <p className="text-xl">🎯 <strong>Purpose:</strong> {generatedQuestion.purpose}</p>
                          </div>

                          {Array.isArray(generatedQuestion.steps) && generatedQuestion.steps.length > 0 && (
                            <div className="mb-8">
                              <h3 className="text-2xl font-bold mb-4">Steps</h3>
                              <ol className="list-decimal list-outside pl-5 space-y-3 text-xl">
                                {generatedQuestion.steps.map((step, index) => (
                                  <li key={index} className="text-gray-700">{step}</li>
                                ))}
                              </ol>
                            </div>
                          )}

                          {Array.isArray(generatedQuestion.facilitatorNotes) && generatedQuestion.facilitatorNotes.length > 0 && (
                            <div>
                              <h3 className="text-2xl font-bold mb-4">Facilitator Notes</h3>
                              <ul className="list-disc list-outside pl-5 space-y-3 text-xl">
                                {generatedQuestion.facilitatorNotes.map((note, index) => (
                                  <li key={index} className="text-gray-700">{note}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      );
                    } catch (e) {
                      console.error('Error displaying energizer:', e, generatedQuestion);
                      return (
                        <div className="text-red-500 text-center">
                          <p>Error displaying energizer</p>
                          <pre className="text-left text-sm mt-4 bg-gray-100 p-4 rounded overflow-auto">
                            {JSON.stringify(generatedQuestion, null, 2)}
                          </pre>
                        </div>
                      );
                    }
                  })()}
                </div>
              ) : (
                <div className="text-4xl font-bold text-center mb-6">
                  &ldquo;{generatedQuestion.content}&rdquo;
                </div>
              )}
              {user && (
                <MemoizedQuestionActions
                  question={generatedQuestion}
                  onVote={fetchPreviousQuestions}
                />
              )}
            </>
          )}
        </div>

        {/* Controls Box */}
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            {/* Type Column */}
            <div>
              <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Type</p>
              <div className="space-y-1 md:space-y-2">
                <ControlOption
                  label="Check-In"
                  emoji="👋"
                  isSelected={selectedType === 'check-in'}
                  onChange={() => handleTypeChange('check-in')}
                />
                <ControlOption
                  label="Energizer"
                  emoji="⚡️"
                  isSelected={selectedType === 'energizer'}
                  onChange={() => handleTypeChange('energizer')}
                />
                <ControlOption
                  label="Check-Out"
                  emoji="🏁"
                  isSelected={selectedType === 'check-out'}
                  onChange={() => handleTypeChange('check-out')}
                />
              </div>
            </div>

            {/* Mood Column */}
            <div>
              <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Mood</p>
              <div className="space-y-1 md:space-y-2">
                <ControlOption
                  label="Curious"
                  emoji="🤔"
                  isSelected={selectedMood === 'curious'}
                  onChange={() => handleMoodChange('curious')}
                />
                <ControlOption
                  label="Personal"
                  emoji="👤"
                  isSelected={selectedMood === 'personal'}
                  onChange={() => handleMoodChange('personal')}
                />
                <ControlOption
                  label="Weird"
                  emoji="🌀"
                  isSelected={selectedMood === 'weird'}
                  onChange={() => handleMoodChange('weird')}
                />
              </div>
            </div>

            {/* Complexity Column */}
            <div>
              <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Complexity</p>
              <div className="space-y-1 md:space-y-2">
                <ControlOption
                  label="Simple"
                  emoji="✅"
                  isSelected={selectedComplexity === 'simple'}
                  onChange={() => handleComplexityChange('simple')}
                />
                <ControlOption
                  label="Moderate"
                  emoji="🔸"
                  isSelected={selectedComplexity === 'moderate'}
                  onChange={() => handleComplexityChange('moderate')}
                />
                <ControlOption
                  label="Deep"
                  emoji="🧠"
                  isSelected={selectedComplexity === 'deep'}
                  onChange={() => handleComplexityChange('deep')}
                />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <button
              onClick={handleGenerate}
              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-8 rounded-xl text-xl transition duration-200 ease-in-out transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:bg-gray-400"
              disabled={isLoading || (!user && generationsLeft === 0)}
            >
              {isLoading 
                ? 'Generating...' 
                : !user && generationsLeft === 0
                ? 'Free limit reached'
                : 'Generate'}
            </button>
            {!user && (
              <div className="text-center mt-4">
                <p className="text-gray-600 mb-2">
                  {generationsLeft > 0 
                    ? `${generationsLeft} free generations remaining`
                    : "You've reached the limit for free generations"}
                </p>
                <a 
                  href="/sign-up" 
                  className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
                >
                  Create account <span className="ml-1">→</span>
                </a>
              </div>
            )}
          </div>
        </div>

        {/* Premium Upgrade Box - Only show for non-premium users */}
        {user && !isPremium && !isLoading && (
          <div className="mt-6 mb-6 bg-gradient-to-r from-blue-600 to-blue-700 rounded-3xl text-white p-8">
            <div className="flex flex-col md:flex-row items-start gap-8">
              <div className="flex-1 space-y-6">
                <div>
                  <h2 className="text-2xl font-bold mb-2">Check-in Questions Premium</h2>
                  <p className="opacity-90">Your all-in-one solution for creating engaging team moments and fostering psychological safety.</p>
                </div>

                <ul className="space-y-3">
                  <li className="flex items-center">
                    <span className="mr-3">✨</span>
                    Unlimited access to our AI-powered question generator
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">🎯</span>
                    Advanced energizer activities and team exercises
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">📈</span>
                    Access to top-rated questions from our global community
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">🌐</span>
                    Monthly online workshops on facilitation best practices
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">🌐</span>
                    Join our global community of facilitators and team leaders
                  </li>
                </ul>

                <div className="bg-white/10 rounded-xl p-6 space-y-3">
                  <p className="font-semibold">Perfect for team leaders, facilitators, and managers who want to:</p>
                  <ul className="space-y-2 opacity-90">
                    <li>• Create more meaningful team connections</li>
                    <li>• Learn new facilitation techniques</li>
                    <li>• Share experiences with peers globally</li>
                    <li>• Stay updated with latest team engagement practices</li>
                  </ul>
                </div>
              </div>

              <div className="w-full md:w-1/4 flex flex-col items-center md:items-end gap-6">
                <div className="text-center md:text-right">
                  <p className="text-lg opacity-90 mb-1">Starting at</p>
                  <p className="text-4xl font-bold">$5/month</p>
                </div>
                <a
                  href="/pricing"
                  className="inline-block bg-white text-blue-600 font-bold py-3 px-8 rounded-xl text-lg transition duration-200 ease-in-out transform hover:scale-[1.02] hover:bg-gray-50 w-full md:w-auto text-center"
                >
                  Upgrade to Premium
                </a>
                <p className="text-sm opacity-75 text-center md:text-right">
                  Includes access to monthly virtual workshops with experienced facilitators
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Popular Questions - Only show for premium users */}
        {user && subscription?.status === 'premium' && previousQuestions.length > 0 && (
          <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-6">
            <h2 className="text-2xl font-bold mb-6">Popular Check-In Questions</h2>
            <div className="space-y-4">
              {previousQuestions
                .filter(question => {
                  // console.log(`Question ID: ${question.id}, Votes: ${question.voteCount}`);
                  // More explicit type conversion and comparison
                  const votes = parseInt(question.voteCount) || 0;
                  return votes >= 1;
                })
                .slice(0, 20)
                .map((question) => (
                  <div
                    key={question.id}
                    className="bg-white p-4 rounded-lg shadow-md space-y-2"
                  >
                    {question.type === 'energizer' ? (
                      <div className="text-left">
                        {(() => {
                          try {
                            const energizer = typeof question.content === 'string' 
                              ? JSON.parse(question.content) 
                              : question.content;

                            if (energizer.title && energizer.description) {
                              const isExpanded = expandedEnergizers.has(question.id);

                              return (
                                <div className="space-y-4">
                                  {/* Preview Section - Always Visible */}
                                  <div className="space-y-4">
                                    <h2 className="text-4xl font-bold">
                                      {energizer.emoji} {energizer.title}
                                    </h2>
                                    <p className="text-xl">{energizer.description}</p>
                                  </div>

                                  {/* Expandable Section */}
                                  <div className="relative">
                                    <button
                                      onClick={() => toggleEnergizer(question.id)}
                                      className="flex items-center gap-2 text-blue-500 hover:text-blue-600 transition-colors"
                                    >
                                      <span className="font-medium">
                                        {isExpanded ? 'Show less' : 'Read more'}
                                      </span>
                                      {isExpanded ? <CaretUp size={20} /> : <CaretDown size={20} />}
                                    </button>

                                    <div
                                      className={`transition-all duration-300 ease-in-out overflow-hidden ${
                                        isExpanded ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'
                                      }`}
                                    >
                                      <div className="pt-4 space-y-6">
                                        <div className="bg-gray-100 p-6 rounded-lg">
                                          <p className="text-xl mb-2">⏱️ <strong>Time:</strong> {energizer.timeFrame}</p>
                                          <p className="text-xl mb-2">👥 <strong>Group Size:</strong> {energizer.groupSize}</p>
                                          {energizer.materials && energizer.materials !== 'None' && (
                                            <p className="text-xl mb-2">📦 <strong>Materials:</strong> {energizer.materials}</p>
                                          )}
                                          <p className="text-xl">🎯 <strong>Purpose:</strong> {energizer.purpose}</p>
                                        </div>

                                        {Array.isArray(energizer.steps) && energizer.steps.length > 0 && (
                                          <div>
                                            <h3 className="text-2xl font-bold mb-4">Steps</h3>
                                            <ol className="list-decimal list-outside pl-5 space-y-3 text-xl">
                                              {energizer.steps.map((step, index) => (
                                                <li key={index} className="text-gray-700">{step}</li>
                                              ))}
                                            </ol>
                                          </div>
                                        )}

                                        {Array.isArray(energizer.facilitatorNotes) && energizer.facilitatorNotes.length > 0 && (
                                          <div>
                                            <h3 className="text-2xl font-bold mb-4">Facilitator Notes</h3>
                                            <ul className="list-disc list-outside pl-5 space-y-3 text-xl">
                                              {energizer.facilitatorNotes.map((note, index) => (
                                                <li key={index} className="text-gray-700">{note}</li>
                                              ))}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          } catch (e) {
                            console.error('Error parsing energizer content:', e);
                            return <div className="text-gray-700 whitespace-pre-wrap">{question.content}</div>;
                          }
                        })()}
                      </div>
                    ) : (
                      <p className="text-4xl font-bold text-center mb-4">&ldquo;{question.content}&ldquo;</p>
                    )}
                    <div className="flex flex-col items-center">
                      <MemoizedQuestionActions
                        question={question}
                        onVote={fetchPreviousQuestions}
                      />
                      <p className="text-sm font-medium text-gray-700 mt-2">
                        Votes: {question.voteCount}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}