import { useState, useCallback, useMemo, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

// Define constants first
const QUESTION_STRUCTURES = [
  { type: 'reflection', pattern: 'What [insight/lesson/discovery] have you [experienced/learned] recently about [topic]?' },
  { type: 'hypothetical', pattern: 'If you could [action] [timeframe], what would you [do/choose] and why?' },
  { type: 'storytelling', pattern: 'Tell us about a moment when [situation] led to [outcome].' },
  { type: 'metaphorical', pattern: 'If your [current state/feeling/experience] were a [metaphor], what would it be?' },
  { type: 'growth', pattern: 'How has [experience/challenge] shaped your perspective on [topic]?' },
  { type: 'appreciation', pattern: 'What [person/experience/thing] has recently brought unexpected [positive outcome]?' },
  { type: 'future', pattern: 'What [goal/change/possibility] are you most excited about [timeframe]?' },
  { type: 'creativity', pattern: 'How would you describe [topic/feeling/experience] using [creative medium]?' },
  { type: 'connection', pattern: 'What [shared experience/value/interest] has helped you connect with others recently?' },
  { type: 'curiosity', pattern: 'What unexpected [discovery/observation/connection] has sparked your interest lately?' }
];

const TOPIC_CATEGORIES = {
  'personal_growth': {
    themes: ['learning', 'goals', 'challenges', 'achievements', 'skills'],
    contexts: ['work', 'personal life', 'relationships', 'hobbies', 'creativity']
  },
  'team_dynamics': {
    themes: ['collaboration', 'communication', 'trust', 'support', 'feedback'],
    contexts: ['projects', 'meetings', 'decisions', 'celebrations', 'challenges']
  },
  'emotional_intelligence': {
    themes: ['self-awareness', 'empathy', 'resilience', 'motivation', 'adaptability'],
    contexts: ['interactions', 'decisions', 'responses', 'understanding', 'growth']
  },
  'innovation': {
    themes: ['creativity', 'problem-solving', 'ideas', 'experimentation', 'improvement'],
    contexts: ['work processes', 'solutions', 'approaches', 'methods', 'tools']
  },
  'wellbeing': {
    themes: ['balance', 'health', 'energy', 'mindfulness', 'satisfaction'],
    contexts: ['work-life', 'stress management', 'habits', 'activities', 'environment']
  }
};

const ENERGIZER_TEMPLATES = [
  {
    category: 'storytelling',
    patterns: [
      'Share personal stories around a theme',
      'Create collaborative stories',
      'Use metaphors to describe experiences',
      'Share moments of learning or growth'
    ]
  },
  {
    category: 'movement',
    patterns: [
      'Physical team formations',
      'Gesture-based activities',
      'Space utilization exercises',
      'Rhythm and movement games'
    ]
  },
  {
    category: 'creative_expression',
    patterns: [
      'Visual art activities',
      'Music or sound exercises',
      'Word association games',
      'Role-playing scenarios'
    ]
  },
  {
    category: 'problem_solving',
    patterns: [
      'Group puzzles',
      'Strategy challenges',
      'Collaborative building',
      'Pattern recognition games'
    ]
  },
  {
    category: 'reflection',
    patterns: [
      'Guided visualization',
      'Mindfulness exercises',
      'Appreciation circles',
      'Perspective sharing'
    ]
  },
  {
    category: 'team_building',
    patterns: [
      'Trust exercises',
      'Communication games',
      'Cooperation challenges',
      'Team coordination activities'
    ]
  },
  {
    category: 'icebreakers',
    patterns: [
      'Two truths and a lie variations',
      'Speed meeting formats',
      'Common ground discovery',
      'Unique fact sharing'
    ]
  },
  {
    category: 'brainstorming',
    patterns: [
      'Idea chain reactions',
      'Random word association',
      'Visual ideation',
      'Reverse thinking exercises'
    ]
  }
];

// Initialize OpenAI client
let openaiInstance = null;
const getOpenAIClient = () => {
  if (!openaiInstance) {
    const { OpenAI } = require('openai');
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      throw new Error('OpenAI API key is not configured');
    }
    openaiInstance = new OpenAI({
      apiKey,
      dangerouslyAllowBrowser: true
    });
  }
  return openaiInstance;
};

// Get least used topics and structures
const getVarietyElements = (previousQuestions = []) => {
  try {
    const topicUsage = {};
    const structureUsage = {};
    
    // Initialize usage counters
    Object.keys(TOPIC_CATEGORIES).forEach(topic => topicUsage[topic] = 0);
    QUESTION_STRUCTURES.forEach(structure => structureUsage[structure.type] = 0);
    
    // Count usage in previous questions
    previousQuestions.forEach(q => {
      if (q && q.content) {
        Object.entries(TOPIC_CATEGORIES).forEach(([topic, { themes }]) => {
          if (themes.some(theme => q.content.toLowerCase().includes(theme))) {
            topicUsage[topic]++;
          }
        });
        
        QUESTION_STRUCTURES.forEach(structure => {
          if (q.content.toLowerCase().includes(structure.pattern.split('[')[0].toLowerCase())) {
            structureUsage[structure.type]++;
          }
        });
      }
    });
    
    // Get least used elements
    const leastUsedTopics = Object.entries(topicUsage)
      .sort(([,a], [,b]) => a - b)
      .slice(0, 3)
      .map(([topic]) => topic);
      
    const leastUsedStructures = Object.entries(structureUsage)
      .sort(([,a], [,b]) => a - b)
      .slice(0, 3)
      .map(([type]) => QUESTION_STRUCTURES.find(s => s.type === type))
      .filter(Boolean);
      
    return {
      leastUsedTopics: leastUsedTopics.length > 0 ? leastUsedTopics : Object.keys(TOPIC_CATEGORIES).slice(0, 3),
      leastUsedStructures: leastUsedStructures.length > 0 ? leastUsedStructures : QUESTION_STRUCTURES.slice(0, 3)
    };
  } catch (error) {
    console.error('Error in getVarietyElements:', error);
    return {
      leastUsedTopics: Object.keys(TOPIC_CATEGORIES).slice(0, 3),
      leastUsedStructures: QUESTION_STRUCTURES.slice(0, 3)
    };
  }
};

// Get system prompt based on type
const getSystemPrompt = (type, variety) => {
  if (type === 'energizer') {
    return `You are an expert facilitator creating unique team exercises. Each response should be completely different from previous ones. Focus on:
1. Novel and engaging activities
2. Clear, practical instructions
3. Inclusive experiences
4. Meaningful connections
5. Psychological safety

Consider these themes for variety: ${variety.leastUsedTopics.join(', ')}
Important: Never repeat or reuse previous responses. Each exercise must be unique.`;
  }
  
  return `You are an expert in crafting unique, thought-provoking questions. Each question must be completely different from previous ones. Focus on:
1. Novel perspectives
2. Clear, concise wording
3. Open-ended responses
4. Inclusive language
5. Engaging topics

Use these question structures for variety:
${variety.leastUsedStructures.map(s => s.pattern).join('\n')}

Consider these themes: ${variety.leastUsedTopics.map(topic => 
  TOPIC_CATEGORIES[topic].themes.join(', ')
).join(' | ')}

Important:
- Never repeat or reuse previous questions
- Return ONLY the question text, no JSON formatting
- Do not include any additional formatting or metadata
- Each question must be unique`;
};

// Get user prompt based on parameters
const getUserPrompt = (type, mood, complexity, previousQuestions = []) => {
  if (type === 'energizer') {
    // Select a random template category that hasn't been used recently
    const usedCategories = new Set(
      previousQuestions
        .slice(-5)
        .map(q => {
          try {
            const content = typeof q.content === 'string' ? JSON.parse(q.content) : q.content;
            return content.category;
          } catch {
            return null;
          }
        })
        .filter(Boolean)
    );

    const availableTemplates = ENERGIZER_TEMPLATES.filter(t => !usedCategories.has(t.category));
    const template = availableTemplates.length > 0 
      ? availableTemplates[Math.floor(Math.random() * availableTemplates.length)]
      : ENERGIZER_TEMPLATES[Math.floor(Math.random() * ENERGIZER_TEMPLATES.length)];

    const pattern = template.patterns[Math.floor(Math.random() * template.patterns.length)];

    return `Create a ${complexity} group energizer with ${mood} tone, inspired by this pattern: "${pattern}"

Return it in this exact JSON format:
{
  "title": "Unique and creative title",
  "emoji": "Relevant emoji",
  "category": "${template.category}",
  "description": "Brief, engaging description",
  "timeFrame": "Duration (e.g., 5-15 minutes)",
  "groupSize": "Participant range (e.g., 4-12 people)",
  "materials": "Required materials, or 'None required'",
  "purpose": "Clear goal and benefits",
  "steps": [
    "Clear step-by-step instructions",
    "Each step should be actionable",
    "Include setup and wrap-up"
  ],
  "facilitatorNotes": [
    "Important tips for facilitation",
    "Guidance for inclusive participation",
    "Adaptation suggestions"
  ]
}

Requirements:
1. Must be completely different from previous energizers
2. Should be engaging and interactive
3. Focus on building psychological safety
4. Include clear instructions
5. Be adaptable for different group sizes
6. Consider both in-person and remote settings`;
  }

  const variety = getVarietyElements(previousQuestions);
  const suggestedStructure = variety.leastUsedStructures[Math.floor(Math.random() * variety.leastUsedStructures.length)];
  const suggestedTopic = variety.leastUsedTopics[Math.floor(Math.random() * variety.leastUsedTopics.length)];

  return `Create a unique ${complexity} ${type} question with ${mood} tone using this structure as inspiration: "${suggestedStructure.pattern}"

Consider exploring themes from: ${TOPIC_CATEGORIES[suggestedTopic].themes.join(', ')}
Context suggestions: ${TOPIC_CATEGORIES[suggestedTopic].contexts.join(', ')}

Requirements:
- Must be different from: ${previousQuestions.slice(-3).map(q => q.content).join('; ')}
- Under 20 words
- Clear and direct
- Single theme
- Open-ended
- Engaging and thought-provoking`;
};

// Check similarity between contents
const checkSimilarity = async (newContent, previousQuestions) => {
  // For now, implement a simple text-based similarity check
  const isTooSimilar = previousQuestions.some(q => {
    const content = q.content || '';
    const similarity = content.toLowerCase().includes(newContent.toLowerCase()) ||
                      newContent.toLowerCase().includes(content.toLowerCase());
    return similarity;
  });

  return {
    isTooSimilar,
    similarityScore: isTooSimilar ? 1 : 0,
    mostSimilarTo: null,
    reason: isTooSimilar ? 'Content is too similar to existing question' : 'Content is sufficiently unique'
  };
};

// Utility function to clean question content
const cleanQuestionContent = (content) => {
  if (!content) return '';
  
  let cleaned = content;
  
  // Try to parse if it looks like JSON
  if (typeof cleaned === 'string' && cleaned.trim().startsWith('{') && cleaned.trim().endsWith('}')) {
    try {
      const parsed = JSON.parse(cleaned);
      if (parsed.content) {
        cleaned = parsed.content;
      }
    } catch (e) {
      console.log('Content is not valid JSON, using as-is');
    }
  }

  // Remove any quotes wrapping the entire response
  cleaned = cleaned.replace(/^["'](.+)["']$/, '$1');
  
  return cleaned;
};

// Clean energizer content by removing step and note prefixes
const cleanEnergizerContent = (content) => {
  if (!content || typeof content !== 'object') return content;

  const cleanedContent = { ...content };

  // Clean steps array - remove "Step X:" or "Step X." prefixes
  if (Array.isArray(cleanedContent.steps)) {
    cleanedContent.steps = cleanedContent.steps.map(step => 
      step.replace(/^Step\s*\d+[.:]\s*/i, '')
    );
  }

  // Clean facilitator notes - remove "Note X:" or "Note X." prefixes
  if (Array.isArray(cleanedContent.facilitatorNotes)) {
    cleanedContent.facilitatorNotes = cleanedContent.facilitatorNotes.map(note => 
      note.replace(/^Note\s*\d+[.:]\s*/i, '')
    );
  }

  return cleanedContent;
};

// Main question generation function
export const generateOpenAIQuestion = async (type, mood, complexity, prevQuestions = []) => {
  try {
    console.log('🤖 OpenAI Generation');
    console.log('📝 Generating with params:', { type, mood, complexity });
    
    if (type === 'energizer') {
      let completion; // Declare completion variable in the outer scope
      try {
        completion = await getOpenAIClient().chat.completions.create({
          model: "gpt-4-turbo-preview",
          messages: [
            { 
              role: "system", 
              content: `You are an expert facilitator. Return ONLY valid JSON with no markdown. Format:
{
  "title": "Brief title",
  "emoji": "🎯",
  "category": "movement",
  "description": "One sentence description",
  "timeFrame": "5-10 minutes",
  "groupSize": "4-12 people",
  "materials": "None required",
  "purpose": "One clear goal",
  "steps": ["Step 1", "Step 2", "Step 3"],
  "facilitatorNotes": ["Note 1", "Note 2"]
}`
            },
            { 
              role: "user", 
              content: getUserPrompt(type, mood, complexity, prevQuestions) 
            }
          ],
          temperature: 0.7,
          max_tokens: 800,
        });

        console.log('Raw energizer response:', completion.choices[0].message.content);
        
        // Parse and validate the response
        const energizerContent = JSON.parse(completion.choices[0].message.content);
        
        // Validate required fields
        const requiredFields = ['title', 'emoji', 'category', 'description', 'timeFrame', 'groupSize', 'steps'];
        const missingFields = requiredFields.filter(field => !energizerContent[field]);
        
        if (missingFields.length > 0) {
          throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
        }

        return energizerContent;

      } catch (parseError) {
        console.error('Error parsing energizer response:', parseError);
        // Now completion is accessible here
        console.log('Failed response:', completion?.choices[0]?.message?.content);
        throw new Error('Failed to generate valid energizer format');
      }
    }

    // Rest of the existing code for non-energizer questions...
  } catch (error) {
    console.error('Error in generateOpenAIQuestion:', error);
    throw new Error(`Failed to generate ${type}: ${error.message}`);
  }
};

const MAX_FREE_GENERATIONS = 5;

export function useQuestionGenerator() {
  const { supabase, user } = useAuth();

  // Memoized initial generations left
  const initialGenerationsLeft = useMemo(() => {
    const used = localStorage.getItem('generatedQuestions') || '0';
    console.log('Initial free generations left:', MAX_FREE_GENERATIONS - parseInt(used, 10));
    return Math.max(MAX_FREE_GENERATIONS - parseInt(used, 10), 0);
  }, []);

  // State
  const [generationsLeft, setGenerationsLeft] = useState(initialGenerationsLeft);
  const [selectedType, setSelectedType] = useState('check-in');
  const [selectedMood, setSelectedMood] = useState('curious');
  const [selectedComplexity, setSelectedComplexity] = useState('simple');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedQuestion, setGeneratedQuestion] = useState(null);
  const [previousQuestions, setPreviousQuestions] = useState([]);

  // OpenAI generation function
  const generateOpenAIQuestion = useCallback(async (type, mood, complexity, prevQuestions = []) => {
    try {
      console.log('🤖 OpenAI Generation');
      console.log('📝 Generating with params:', { type, mood, complexity });
      
      if (type === 'energizer') {
        let completion; // Declare completion variable in the outer scope
        try {
          completion = await getOpenAIClient().chat.completions.create({
            model: "gpt-4-turbo-preview",
            messages: [
              { 
                role: "system", 
                content: `You are an expert facilitator. Return ONLY valid JSON with no markdown. Format:
{
  "title": "Brief title",
  "emoji": "🎯",
  "category": "movement",
  "description": "One sentence description",
  "timeFrame": "5-10 minutes",
  "groupSize": "4-12 people",
  "materials": "None required",
  "purpose": "One clear goal",
  "steps": ["Step 1", "Step 2", "Step 3"],
  "facilitatorNotes": ["Note 1", "Note 2"]
}`
              },
              { 
                role: "user", 
                content: getUserPrompt(type, mood, complexity, prevQuestions) 
              }
            ],
            temperature: 0.7,
            max_tokens: 800,
          });

          console.log('Raw energizer response:', completion.choices[0].message.content);
          
          // Parse and validate the response
          const energizerContent = JSON.parse(completion.choices[0].message.content);
          
          // Validate required fields
          const requiredFields = ['title', 'emoji', 'category', 'description', 'timeFrame', 'groupSize', 'steps'];
          const missingFields = requiredFields.filter(field => !energizerContent[field]);
          
          if (missingFields.length > 0) {
            throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
          }

          return energizerContent;

        } catch (parseError) {
          console.error('Error parsing energizer response:', parseError);
          // Now completion is accessible here
          console.log('Failed response:', completion?.choices[0]?.message?.content);
          throw new Error('Failed to generate valid energizer format');
        }
      } else {
        // Regular question generation
        const completion = await getOpenAIClient().chat.completions.create({
          model: "gpt-3.5-turbo",
          messages: [
            { 
              role: "system", 
              content: getSystemPrompt(type, getVarietyElements(prevQuestions))
            },
            { 
              role: "user", 
              content: getUserPrompt(type, mood, complexity, prevQuestions)
            }
          ],
          temperature: 0.9,
          max_tokens: 200
        });

        // Get the raw response and clean it
        let response = completion.choices[0].message.content.trim();
        
        // Remove any JSON-like wrapping
        try {
          // Check if the response looks like JSON
          if (response.startsWith('{') && response.endsWith('}')) {
            const parsed = JSON.parse(response);
            if (parsed.content) {
              response = parsed.content;
            }
          }
        } catch (e) {
          // If parsing fails, use the original response
          console.log('Response is not JSON, using as-is');
        }

        // Remove any quotes wrapping the entire response
        response = response.replace(/^["'](.+)["']$/, '$1');
        
        return response;
      }
    } catch (error) {
      console.error('Generation Error:', error);
      throw error;
    }
  }, []);

  // Handlers
  const handleTypeChange = useCallback((type) => {
    console.log('Type changed:', type);
    setSelectedType(type);
    setGeneratedQuestion(null);
  }, []);

  const handleMoodChange = useCallback((mood) => {
    console.log('Mood changed:', mood);
    setSelectedMood(mood);
    setGeneratedQuestion(null);
  }, []);

  const handleComplexityChange = useCallback((complexity) => {
    console.log('Complexity changed:', complexity);
    setSelectedComplexity(complexity);
    setGeneratedQuestion(null);
  }, []);

  // Fetch previous questions
  const fetchPreviousQuestions = useCallback(async () => {
    if (!user) {
      console.log('Skipping previous questions fetch - no user');
      return;
    }

    try {
      const { data: questionsData, error: fetchError } = await supabase
        .from('generated_questions')
        .select('id, question, type, mood, complexity, created_at, votes_questions(vote_type, user_id)')
        .eq('type', selectedType)
        .order('created_at', { ascending: false });

      if (fetchError) throw fetchError;

      if (questionsData) {
        const formattedQuestions = questionsData.map(q => ({
          id: q.id,
          content: q.question,
          type: q.type,
          mood: q.mood,
          complexity: q.complexity,
          voteCount: (q.votes_questions || [])
            .reduce((acc, v) => acc + (v.vote_type === 'helpful' ? 1 : -1), 0),
          userVote: (q.votes_questions || [])
            .find(v => v.user_id === user.id)?.vote_type || null,
        }));

        setPreviousQuestions(formattedQuestions.sort((a, b) => b.voteCount - a.voteCount));
      }
    } catch (error) {
      console.error('Failed to fetch previous questions:', error);
      setError('Failed to fetch previous questions.');
    }
  }, [supabase, user, selectedType]);

  // Get an unserved question
  const getUnservedQuestion = async (type) => {
    console.log('🔍 Attempting to fetch unserved question for type:', type);
    
    try {
      const { data, error } = await supabase
        .from('generated_questions')
        .select('id, question, type, mood, complexity')
        .eq('type', type)
        .eq('is_public', true)
        .is('is_served', false)
        .order('created_at', { ascending: true })
        .limit(1)
        .maybeSingle();

      if (error) {
        console.error('❌ Error fetching unserved question:', error);
        return null;
      }

      if (data) {
        console.log('✨ Found unserved question to serve - ID:', data.id);
        return data;
      }

      console.log('⚠️ No unserved questions available');
      return null;
    } catch (err) {
      console.error('❌ Error in getUnservedQuestion:', err);
      return null;
    }
  };

  // Generate background questions
  const generateBackgroundQuestions = useCallback(async () => {
    if (!supabase || !user) return;

    try {
      console.log('🔄 Background Generation Process');
      console.log('🎯 Starting background generation of 3 questions...');
      const questions = [];
      
      for (let i = 0; i < 3; i++) {
        console.log(`📝 Generating Question ${i + 1}/3`);
        
        const generatedContent = await generateOpenAIQuestion(
          selectedType,
          selectedMood,
          selectedComplexity,
          previousQuestions
        );

        // Add this log to show the generated content
        console.log(`✨ Generated Question ${i + 1}:`, 
          selectedType === 'energizer' 
            ? JSON.stringify(generatedContent, null, 2)
            : generatedContent
        );

        questions.push({
          user_id: user.id,
          type: selectedType,
          mood: selectedMood,
          complexity: selectedComplexity,
          question: selectedType === 'energizer' ? JSON.stringify(generatedContent) : generatedContent,
          created_at: new Date().toISOString(),
          is_served: false,
          is_public: true
        });
      }

      const { error: insertError } = await supabase
        .from('generated_questions')
        .insert(questions);

      if (insertError) {
        console.error('Error inserting background questions:', insertError);
        return;
      }

      console.log('✅ Background generation completed - 3 questions added to pool');

      // Add statistics logging
      const { data: stats, error: statsError } = await supabase
        .from('generated_questions')
        .select('type, is_served')
        .eq('is_public', true);

      if (statsError) {
        console.error('Error fetching question statistics:', statsError);
        return;
      }

      // Calculate statistics
      const questionStats = stats.reduce((acc, q) => {
        // Initialize if not exists
        if (!acc[q.type]) {
          acc[q.type] = { total: 0, unserved: 0 };
        }
        
        acc[q.type].total++;
        if (!q.is_served) {
          acc[q.type].unserved++;
        }
        
        return acc;
      }, {
        'check-in': { total: 0, unserved: 0 },
        'energizer': { total: 0, unserved: 0 },
        'check-out': { total: 0, unserved: 0 }
      });

      console.log('📊 Question Pool Statistics:');
      Object.entries(questionStats).forEach(([type, counts]) => {
        const displayType = type === 'check-in' ? 'Check-in' 
                         : type === 'energizer' ? 'Energizer' 
                         : 'Check-out';
        console.log(`${displayType}: ${counts.total} total (${counts.unserved} unserved)`);
      });

    } catch (err) {
      console.error('❌ Background generation error:', err);
    }
  }, [supabase, user, selectedType, selectedMood, selectedComplexity, previousQuestions, generateOpenAIQuestion]);

  // Generate question
  const generateQuestion = useCallback(async () => {
    try {
      console.log('🎯 Starting Question Generation Process');
      console.log('👤 User status:', user ? 'Logged in' : 'Non-logged in');
      console.log('📋 Parameters:', { selectedType, selectedMood, selectedComplexity });
      
      setIsLoading(true);
      setError(null);

      // First try to serve an unserved question for all users
      const unservedQuestion = await getUnservedQuestion(selectedType);
      
      if (unservedQuestion) {
        console.log('💾 Serving unserved question from database');
        
        // Mark question as served
        const { error: updateError } = await supabase
          .from('generated_questions')
          .update({ is_served: true })
          .eq('id', unservedQuestion.id);

        if (updateError) {
          console.error('❌ Error marking question as served:', updateError);
        }

        const content = selectedType === 'energizer' 
          ? cleanEnergizerContent(JSON.parse(unservedQuestion.question))
          : cleanQuestionContent(unservedQuestion.question);
          
        setGeneratedQuestion({
          id: unservedQuestion.id,
          ...(selectedType === 'energizer' ? content : { content }),
          type: selectedType,
          mood: selectedMood,
          complexity: selectedComplexity,
          isFromDatabase: true
        });

        // If user is logged in, trigger background generation
        if (user) {
          console.log('🔄 Starting background generation of 3 new questions');
          generateBackgroundQuestions().catch(console.error);
        }
        
        setIsLoading(false);
        return;
      }

      // Rest of your existing generateQuestion logic...
      
    } catch (err) {
      console.error('❌ Generation error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedType, selectedMood, selectedComplexity, previousQuestions, user]);

  // Fetch previous questions on mount and when selectedType changes
  useEffect(() => {
    if (user) {
      console.log('Initiating previous questions fetch for user:', user.id);
      fetchPreviousQuestions();
    }
  }, [user, selectedType, fetchPreviousQuestions]);

  return {
    generationsLeft,
    selectedType,
    selectedMood,
    selectedComplexity,
    isLoading,
    error,
    generatedQuestion,
    previousQuestions,
    handleTypeChange,
    handleMoodChange,
    handleComplexityChange,
    generateQuestion,
    fetchPreviousQuestions
  };
} 