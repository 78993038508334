import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { trackSignUp, trackError } from '../lib/analytics';
import GoogleSignInButton from './GoogleSignInButton';

export default function SignUp() {
    const { signUp } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);
        
        try {
            console.log('Starting signup process for email:', email);
            const { data, error: signUpError } = await signUp({
                email,
                password,
                fullName
            });

            console.log('Signup response:', { data, error: signUpError });

            if (signUpError) throw signUpError;

            console.log('Signup successful, navigating to verification page');
            trackSignUp('email');
            navigate('/verify-email');
        } catch (err) {
            console.error('Detailed signup error:', err);
            let errorMessage = 'Failed to create account.';
            
            // Log the full error object
            console.log('Full error object:', {
                message: err.message,
                status: err.status,
                name: err.name,
                stack: err.stack
            });
            
            if (err.message?.includes('Email rate limit exceeded')) {
                errorMessage = 'Too many signup attempts. Please wait 60 minutes before trying again. This helps us prevent spam and protect our users.';
                console.log('Rate limit error:', err);
            } else if (err.message?.includes('already registered')) {
                errorMessage = 'This email is already registered. Please sign in instead.';
            } else if (err.message?.includes('valid email')) {
                errorMessage = 'Please enter a valid email address.';
            } else if (err.message?.includes('SMTP') || err.message?.includes('email')) {
                errorMessage = 'There was an issue sending the verification email. Please try again later or contact support.';
                // Log SMTP-specific errors for debugging
                console.error('SMTP Error:', {
                    message: err.message,
                    code: err.code,
                    details: err.details
                });
            }
            
            setError(errorMessage);
            trackError('signup_error', { 
                error: errorMessage,
                originalError: err.message,
                errorCode: err.code
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold mb-4">Create Your Free Account</h2>
                    <p className="text-lg text-gray-600 mb-4">
                        Get started with 5 free questions per day and unlock the power of meaningful team interactions.
                    </p>
                    <div className="inline-flex items-center space-x-2 text-sm text-gray-500">
                        <span>Looking for more?</span>
                        <Link to="/pricing" className="text-blue-600 hover:text-blue-700 font-medium">
                            View our Premium features →
                        </Link>
                    </div>
                </div>

                <div className="bg-gray-50 rounded-xl p-6 mb-8">
                    <h3 className="font-semibold mb-4">What you'll get:</h3>
                    <ul className="space-y-3">
                        <li className="flex items-center text-gray-700">
                            <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            5 free questions every day
                        </li>
                        <li className="flex items-center text-gray-700">
                            <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            Save your favorite questions
                        </li>
                        <li className="flex items-center text-gray-700">
                            <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            Access to basic question types
                        </li>
                    </ul>
                </div>

                <form onSubmit={handleSignUp} className="space-y-4">
                    <div>
                        <label htmlFor="fullName" className="sr-only">Full Name</label>
                        <input
                            id="fullName"
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Full Name"
                            required
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
                        />
                    </div>
                    
                    <div>
                        <label htmlFor="email" className="sr-only">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
                        />
                    </div>
                    
                    <div>
                        <label htmlFor="password" className="sr-only">Password</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full p-2 bg-black text-white rounded hover:bg-gray-800 disabled:opacity-50"
                    >
                        {loading ? "Creating Account..." : "Create Free Account"}
                    </button>

                    <div className="relative my-6">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">Or continue with</span>
                        </div>
                    </div>

                    <GoogleSignInButton onError={setError} />
                </form>

                {error && (
                    <div className="mt-4 p-4 bg-red-50 rounded-lg">
                        <p className="text-sm text-red-600">{error}</p>
                        {error.includes('verification email') && (
                            <p className="text-xs text-red-500 mt-1">
                                If this persists, please ensure your email address is correct or try again later.
                            </p>
                        )}
                    </div>
                )}

                <p className="mt-4 text-center text-sm text-blue-500">
                    <Link to="/sign-in">
                        Already have an account? Sign in
                    </Link>
                </p>
            </div>
        </div>
    );
}